import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import HeadingH2 from "./HeadingH2";
import jsPDF from "jspdf";
import logo from "../assets/media/pdf_logo.png";
import photo1 from "../assets/media/pdf_foto1.jpg";
import photo2 from "../assets/media/pdf_foto2.jpg";
const RevinedCalc = () => {
  const [shipmentsPerYear, setShipmentsPerYear] = useState("");
  const [totalVolume, setTotalVolume] = useState("");
  const [logisticCostsBottles, setLogisticCostsBottles] = useState("");
  const [logisticCostsTubes, setLogisticCostsTubes] = useState("");
  const [fobPricePerBottle, setFobPricePerBottle] = useState("");
  const [isSwitched, setIsSwitched] = useState(false);
  const handleShipmentsPerYearChange = (e) => {
    const value = e.target.value;
    setShipmentsPerYear(value);
    setTotalVolume(value * 5);
  };
  const handleLogisticCosts = (e) => {
    const value = e.target.value;
    setLogisticCostsBottles(value);
    setLogisticCostsTubes(value / 4);
  };
  const handleFobPricePerBottleChange = (e) => {
    const value = e.target.value;
    setFobPricePerBottle(value);
  };
  const handleSwitchChange = (e) => {
    setIsSwitched(e.target.checked);
  };
  const handleReset = () => {
    setShipmentsPerYear("");
    setTotalVolume("");
    setLogisticCostsBottles("");
    setLogisticCostsTubes("");
    setFobPricePerBottle("");
  };
  const isAllFieldsFilled =
    shipmentsPerYear &&
    totalVolume &&
    logisticCostsBottles &&
    fobPricePerBottle;
  function formatNumber(num, fixed) {
    if (num === 0 || isNaN(num) || num === Infinity || num === "") {
      return "";
    } else {
      if (fixed === 0) {
        return num;
      } else if (fixed === 2) {
        if (isNaN(num)) {
          return "";
        }
        return parseFloat(num).toFixed(2);
      } else {
        if (isNaN(num)) {
          return "";
        }
        return parseFloat(num).toFixed(0);
      }
    }
  }
  const totalBottlesNeeded = isSwitched
    ? (shipmentsPerYear / 7.4) * 5
    : (shipmentsPerYear / 14) * 5;
  const bottleSaved = totalVolume - totalBottlesNeeded;
  const comIncrementalValSaved = fobPricePerBottle * bottleSaved;
  const logisticCostsSaved =
    (logisticCostsBottles - logisticCostsTubes) * shipmentsPerYear;
  const totalIncrementalSavingPerYear =
    comIncrementalValSaved + logisticCostsSaved;
  const tubesNeeded = totalVolume;
  const packsNeeded = tubesNeeded / 5;
  const totaalCostPerYear = tubesNeeded * 2.5 + tubesNeeded * 1;
  const totaalCostsPerMachine = 4950;
  const totalCosts = totaalCostPerYear + totaalCostsPerMachine;
  const savingsPerMonth = (comIncrementalValSaved + logisticCostsSaved) / 12;
  const roiInMonths = totalCosts / savingsPerMonth;
  const generatePDF = () => {
    const doc = new jsPDF();
    const sectionJSX = (
      <>
        <section className="p-16 flex" style={{ width: 1912 }}>
          <div className="w-3/5">
            <h2 className="relative mb-5 w-full">
              <span className="absolute bg-primaryGold h-full w-2 -left-5"></span>
              <span>A GAME-CHANGING SOLUTION</span>
            </h2>
            <p className="mb-8 text-xl">
              Introducing TUBES Revined, a revolutionary solution designed to
              transform wine and spirits sampling programs for wineries,
              distributors, and (e)retailers alike. This innovative machine
              enables you to effortlessly bottle your own samples in 50- and
              100-ml glass tubes, extending the shelf life of wines by 4-6
              months, or up to an impressive 12 months for younger wines.
            </p>
            <p className="mb-8 text-xl">
              With the ability to create 7 to 14 samples from a single 750ml
              bottle, TUBES Revined not only delivers substantial savings on
              sample costs and shipping expenses but also reduces environmental
              impact. Moreover, our fully customizable packaging aligns
              seamlessly with your brand’s identity, elevating your business’
              sampling strategy.
            </p>
            <p className="mb-8 text-xl">
              The sleek stainless-steel design of our machine not only reflects
              our commitment to quality and efficiency but also offers adaptable
              solutions that enhance the tasting experience of wines and
              spirits. By continuously pushing boundaries in the industry, we
              provide more choices and opportunities for innovative exploration.
            </p>
          </div>
          <div className="w-1/3"></div>
        </section>
        <section
          className="p-16 bg-primaryGold text-white"
          style={{ width: 1912 }}
        >
          <div className="px-4">
            <h2 className="relative mb-5 w-full">
              <span className="absolute bg-white h-full w-2 -left-5"></span>
              <span>Discover how much you can save:</span>
            </h2>
            <div className="lg:flex lg:gap-4 text-base overflow-hidden">
              <div className="flex flex-col w-full lg:w-1/2">
                <h3 className="text-2xl lg:text-3xl mt-4 mb-5 text-white">
                  Calculator
                </h3>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">
                      Number of sample shipments per year
                    </label>
                    <input
                      type="number"
                      value={shipmentsPerYear}
                      className="border-2 border-white rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <div>
                      <label className="mb-2">
                        Total volume of sample bottles per year
                      </label>
                      <p className="text-sm text-gray-100">
                        Based on an average of 5 bottles per shipment
                      </p>
                    </div>
                    <input
                      type="number"
                      value={totalVolume}
                      className="border-2 border-white rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <div>
                      <label className="mb-2">
                        Average logistic costs per sample shipment of bottles
                        (6.25kg)
                      </label>
                      <p className="text-sm text-gray-100">
                        Based on an average of 5 bottles per shipment
                      </p>
                    </div>
                    <div className="relative ms-3 border-2 border-white rounded-md w-full lg:w-40">
                      <span className="absolute left-3 top-2">&euro;</span>
                      <input
                        type="number"
                        value={logisticCostsBottles}
                        className="px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <div>
                      <label className="mb-2">
                        Average logistic costs per sample shipment of tubes
                        (1.2kg)
                      </label>
                      <p className="text-sm text-gray-100">
                        Based on 1/4 cost vs full-sized bottles
                      </p>
                    </div>
                    <div className="relative ms-3 border-2 border-white rounded-md w-full lg:w-40">
                      <span className="absolute left-3 top-2">&euro;</span>
                      <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                        {logisticCostsTubes.toLocaleString("nl-NL", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">Average FOB price per bottle</label>
                    <div className="relative ms-3 border-2 border-white rounded-md w-full lg:w-40">
                      <span className="absolute left-3 top-2">&euro;</span>
                      <input
                        type="number"
                        value={formatNumber(fobPricePerBottle, 0)}
                        className="text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:flex flex-col w-full lg:w-1/2 lg:ps-4 transform translate-x-0 transition-transform duration-500">
                <h3 className="text-2xl lg:text-3xl mt-4 mb-5 text-secondary">
                  Results
                </h3>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">
                      Total bottles needed by bottling in tubes on 5 SKUS
                    </label>
                    <input
                      type="number"
                      value={formatNumber(totalBottlesNeeded, 1)}
                      className="border-2 border-white rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">
                      Number of bottles saved tubes vs bottles
                    </label>
                    <input
                      type="number"
                      value={formatNumber(bottleSaved, 1)}
                      className="border-2 border-white rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">
                      Commercial incremental value saved
                    </label>
                    <div className="relative ms-3 border-2 border-white rounded-md w-full lg:w-40">
                      <span className="absolute left-3 top-2">&euro;</span>
                      <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                        {comIncrementalValSaved.toLocaleString("nl-NL", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">Logistic costs saved</label>
                    <div className="relative ms-3 border-2 border-white rounded-md w-full lg:w-40">
                      <span className="absolute left-3 top-2">&euro;</span>
                      <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                        {logisticCostsSaved.toLocaleString("nl-NL", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">
                      Total incremental saving per year
                    </label>
                    <div className="relative ms-3 border-2 border-white rounded-md w-full lg:w-40">
                      <span className="absolute left-3 top-2">&euro;</span>
                      <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                        {totalIncrementalSavingPerYear.toLocaleString("nl-NL", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:flex lg:gap-4 overflow-hidden relative">
              <div className="lg:flex flex-col w-full lg:w-1/2 transform translate-y-0 transition-transform duration-500">
                <h3 className="text-2xl lg:text-3xl mt-4 mb-5 text-secondary">
                  ROI
                </h3>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">Number of tubes needed</label>
                    <input
                      type="number"
                      value={formatNumber(tubesNeeded, 1)}
                      className="border-2 border-white rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">
                      Number of packs based on 5 SKUS
                    </label>
                    <input
                      type="number"
                      value={formatNumber(packsNeeded, 1)}
                      className="border-2 border-white rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">Total costs per year</label>
                    <div className="relative ms-3 border-2 border-white rounded-md w-full lg:w-40">
                      <span className="absolute left-3 top-2">&euro;</span>
                      <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                        {totaalCostPerYear.toLocaleString("nl-NL", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">Total costs per machine</label>
                    <div className="relative ms-3 border-2 border-white rounded-md w-full lg:w-40">
                      <span className="absolute left-3 top-2">&euro;</span>
                      <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                        {totaalCostsPerMachine.toLocaleString("nl-NL", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">Total costs</label>
                    <div className="relative ms-3 border-2 border-white rounded-md w-full lg:w-40">
                      <span className="absolute left-3 top-2">&euro;</span>
                      <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                        {totalCosts.toLocaleString("nl-NL", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">Average savings per month</label>
                    <div className="relative ms-3 border-2 border-white rounded-md w-full lg:w-40">
                      <span className="absolute left-3 top-2">&euro;</span>
                      <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                        {savingsPerMonth.toLocaleString("nl-NL", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">ROI in months</label>
                    <input
                      type="number"
                      value={formatNumber(roiInMonths, 1)}
                      className="border-2 border-white rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div
                className={`lg:flex flex-col w-full lg:w-1/2 lg:ps-4 ${
                  isAllFieldsFilled
                    ? "transform translate-y-0 transition-transform duration-500"
                    : "transform -translate-y-full invisible hidden transition-transform duration-500"
                }`}
              >
                <h3 className="text-2xl lg:text-3xl mt-4 mb-5 text-secondary">
                  Carbon Footprint
                </h3>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">Number of shipments per year</label>
                    <input
                      type="number"
                      value={formatNumber(shipmentsPerYear, 0)}
                      className="border-2 border-white rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                    />
                  </div>
                </div>
                <div className="mb-4 hidden">
                  <div className="flex flex-wrap justify-between">
                    <div>
                      <label className="mb-2">
                        C02 on shipping 1000KM 0,015 pkg
                      </label>
                      <p className="text-sm text-gray-500">
                        1,25kg vs 200 gram =0,8kg vs 0,13333
                      </p>
                    </div>
                    <div>
                      <input
                        type="number"
                        value={formatNumber(0.015 * totalVolume, 2)}
                        className="border-2 border-white rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                      />
                      <p className="text-right text-sm">
                        KG of C02
                        <br />
                        shipping saving
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-4 hidden">
                  <div className="flex flex-wrap justify-between">
                    <div>
                      <label className="mb-2">C02 on bottle production</label>
                      <p className="text-sm text-gray-100">
                        100 gram lighter = 20% reduction
                      </p>
                    </div>
                    <input
                      type="number"
                      value={formatNumber((0.8 - 0.13333) * totalVolume, 1)}
                      className="border-2 border-white rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">CO2 savings in KG per year</label>
                    <input
                      type="number"
                      value={formatNumber(
                        0.015 * totalVolume + (0.8 - 0.13333) * totalVolume,
                        1
                      )}
                      className="border-2 border-white rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-wrap justify-between">
                    <label className="mb-2">
                      CO2 savings in trees per year
                    </label>
                    <div>
                      <input
                        type="number"
                        value={formatNumber(
                          (0.015 * totalVolume +
                            (0.8 - 0.13333) * totalVolume) /
                            20,
                          1
                        )}
                        className="border-2 border-white rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
    const section = ReactDOMServer.renderToStaticMarkup(sectionJSX);
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const contentWidth = 1912;
    const scaleFactor = pageWidth / contentWidth;
    // doc.setFillColor(20, 23, 43);
    // doc.rect(
    //   0,
    //   0,
    //   doc.internal.pageSize.getWidth(),
    //   doc.internal.pageSize.getHeight(),
    //   "F"
    // );
    doc.addImage(logo, "PNG", 0, 0, 100, 43);
    doc.addImage(
      photo1,"JPG",
      15, pageHeight - 90, 180, 120
    );
    doc.addImage(
      photo2, "JPG", 100, 0, 150, 100 
    );
    doc.html(section, {
      callback: function (doc) {
        doc.save("Revined-calc.pdf");
      },
      x: 0,
      y: 40,
      html2canvas: { scale: scaleFactor },
    });
  };
  return (
    <section className=" bg-primaryBlue py-8 revCalc">
      <div className="container text-white">
        <HeadingH2>Discover how much you can save:</HeadingH2>
        <div className="lg:flex lg:gap-4 text-base overflow-hidden">
          <div className="flex flex-col w-full lg:w-1/2">
            <h3 className="text-2xl lg:text-3xl mt-4 mb-5 text-secondary">
              Calculator
            </h3>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">
                  Number of sample shipments per year
                </label>
                <input
                  type="number"
                  value={shipmentsPerYear}
                  onChange={handleShipmentsPerYearChange}
                  className="border border-gray-300 rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <div>
                  <label className="mb-2">
                    Total volume of sample bottles per year
                  </label>
                  <p className="text-sm text-gray-500">
                    Based on an average of 5 bottles per shipment
                  </p>
                </div>
                <input
                  type="number"
                  placeholder="0"
                  value={totalVolume}
                  readOnly
                  className="focus:outline-none border border-none rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <div>
                  <label className="mb-2">
                    Average logistic costs per sample shipment of bottles
                    (6.25kg)
                  </label>
                  <p className="text-sm text-gray-500">
                    Based on an average of 5 bottles per shipment
                  </p>
                </div>
                <div className="relative ms-3 border border-gray-300 rounded-md w-full lg:w-40">
                  <span className="absolute left-3 top-2">&euro;</span>
                  <input
                    type="number"
                    step="0.01"
                    min="0"
                    placeholder="0.00"
                    value={logisticCostsBottles}
                    onChange={handleLogisticCosts}
                    className="text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent"
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <div>
                  <label className="mb-2">
                    Average logistic costs per sample shipment of tubes (1.2kg)
                  </label>
                  <p className="text-sm text-gray-500">
                    Based on 1/4 cost vs full-sized bottles
                  </p>
                </div>
                <div className="relative ms-3 rounded-md">
                  <span className="absolute left-3 top-2">&euro;</span>
                  <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                    {logisticCostsTubes.toLocaleString("nl-NL", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">Average FOB price per bottle</label>
                <div className="relative ms-3 border border-gray-300 rounded-md w-full lg:w-40">
                  <span className="absolute left-3 top-2">&euro;</span>
                  <input
                    type="number"
                    step="0.01"
                    min="0"
                    placeholder="0.00"
                    value={formatNumber(fobPricePerBottle, 0)}
                    onChange={handleFobPricePerBottleChange}
                    className="text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent"
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <button
                  className="h-100 px-[1rem] py-[.5rem] bg-white text-primaryBlue fill-primaryBlue font-bold duration-300 hover:bg-primaryGold hover:text-white hover:fill-white"
                  onClick={handleReset}
                >
                  Reset
                  <span className="inline-block  ms-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="13"
                      viewBox="0 0 16 13"
                    >
                      <g>
                        <g>
                          <path d="M9.583 0L7.966 1.655l3.638 3.685H0v2.32h11.604l-3.638 3.685L9.583 13 16 6.5z" />
                        </g>
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            className={`lg:flex flex-col w-full lg:w-1/2 lg:ps-4 ${
              isAllFieldsFilled
                ? "transform translate-x-0 transition-transform duration-500"
                : "transform translate-x-full invisible hidden transition-transform duration-500"
            }`}
          >
            <h3 className="text-2xl lg:text-3xl mt-4 mb-5 text-secondary">
              Results
            </h3>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <button 
              className="h-100 px-[1rem] py-[.5rem] bg-white text-primaryBlue fill-primaryBlue font-bold duration-300 hover:bg-primaryGold hover:text-white hover:fill-white"
              onClick={generatePDF}
            >
              Generate PDF
              <span className="inline-block  ms-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                  >
                    <g>
                      <g>
                        <path d="M9.583 0L7.966 1.655l3.638 3.685H0v2.32h11.604l-3.638 3.685L9.583 13 16 6.5z" />
                      </g>
                    </g>
                  </svg>
                </span>
                </button>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">100ml or 50ml</label>
                <div className="relative inline-block w-24 h-6">
                  <input
                    type="checkbox"
                    checked={isSwitched}
                    onChange={handleSwitchChange}
                    className="toggle-checkbox absolute block w-12 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                    style={{
                      transform: isSwitched
                        ? "translateX(100%)"
                        : "translateX(0)",
                    }}
                  />
                  <label
                    htmlFor="toggle"
                    className="flex items-center justify-around text-sm text-white toggle-label overflow-hidden h-6 rounded-full bg-primaryD"
                  >
                    <span>100ml</span>
                    <span>50ml</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">
                  Total bottles needed by bottling in tubes on 5 SKUS
                </label>
                <input
                  type="number"
                  value={formatNumber(totalBottlesNeeded, 1)}
                  readOnly
                  className="focus:outline-none border border-gray-300 rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">
                  Number of bottles saved tubes vs bottles
                </label>
                <input
                  type="number"
                  value={formatNumber(bottleSaved, 1)}
                  readOnly
                  className="focus:outline-none border border-gray-300 rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">
                  Commercial incremental value saved
                </label>
                <div className="relative ms-3 border border-gray-300 rounded-md w-full lg:w-40">
                  <span className="absolute left-3 top-2">&euro;</span>
                  <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                    {comIncrementalValSaved.toLocaleString("nl-NL", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">Logistic costs saved</label>
                <div className="relative ms-3 border border-gray-300 rounded-md w-full lg:w-40">
                  <span className="absolute left-3 top-2">&euro;</span>
                  <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                    {logisticCostsSaved.toLocaleString("nl-NL", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">
                  Total incremental saving per year
                </label>
                <div className="relative ms-3 border border-gray-300 rounded-md w-full lg:w-40">
                  <span className="absolute left-3 top-2">&euro;</span>
                  <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                    {totalIncrementalSavingPerYear.toLocaleString("nl-NL", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            isAllFieldsFilled ? "lg:flex" : "lg:hidden"
          } lg:gap-4 overflow-hidden relative`}
        >
          <div
            className={`lg:flex flex-col w-full lg:w-1/2 ${
              isAllFieldsFilled
                ? "transform translate-y-0 transition-transform duration-500"
                : "transform -translate-y-full invisible hidden transition-transform duration-500"
            }`}
          >
            <h3 className="text-2xl lg:text-3xl mt-4 mb-5 text-secondary">
              ROI
            </h3>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">Number of tubes needed</label>
                <input
                  type="number"
                  value={formatNumber(tubesNeeded, 1)}
                  readOnly
                  className="focus:outline-none border border-gray-300 rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">Number of packs based on 5 SKUS</label>
                <input
                  type="number"
                  value={formatNumber(packsNeeded, 1)}
                  readOnly
                  className="focus:outline-none border border-gray-300 rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">Total costs per year</label>
                <div className="relative ms-3 border border-gray-300 rounded-md w-full lg:w-40">
                  <span className="absolute left-3 top-2">&euro;</span>
                  <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                    {totaalCostPerYear.toLocaleString("nl-NL", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">Total costs per machine</label>
                <div className="relative ms-3 border border-gray-300 rounded-md w-full lg:w-40">
                  <span className="absolute left-3 top-2">&euro;</span>
                  <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                    {totaalCostsPerMachine.toLocaleString("nl-NL", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">Total costs</label>
                <div className="relative ms-3 border border-gray-300 rounded-md w-full lg:w-40">
                  <span className="absolute left-3 top-2">&euro;</span>
                  <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                    {totalCosts.toLocaleString("nl-NL", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">Average savings per month</label>
                <div className="relative ms-3 border border-gray-300 rounded-md w-full lg:w-40">
                  <span className="absolute left-3 top-2">&euro;</span>
                  <span className="block text-right rounded-md px-3 py-2 w-full lg:w-40 bg-transparent">
                    {savingsPerMonth.toLocaleString("nl-NL", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">ROI in months</label>
                <input
                  type="number"
                  value={formatNumber(roiInMonths, 1)}
                  readOnly
                  className="focus:outline-none border border-gray-300 rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                />
              </div>
            </div>
          </div>
          <div
            className={`lg:flex flex-col w-full lg:w-1/2 lg:ps-4 ${
              isAllFieldsFilled
                ? "transform translate-y-0 transition-transform duration-500"
                : "transform -translate-y-full invisible hidden transition-transform duration-500"
            }`}
          >
            <h3 className="text-2xl lg:text-3xl mt-4 mb-5 text-secondary">
              Carbon Footprint
            </h3>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">Number of shipments per year</label>
                <input
                  type="number"
                  value={formatNumber(shipmentsPerYear, 0)}
                  readOnly
                  className="focus:outline-none border border-gray-300 rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                />
              </div>
            </div>
            <div className="mb-4 hidden">
              <div className="flex flex-wrap justify-between">
                <div>
                  <label className="mb-2">
                    C02 on shipping 1000KM 0,015 pkg
                  </label>
                  <p className="text-sm text-gray-500">
                    1,25kg vs 200 gram =0,8kg vs 0,13333
                  </p>
                </div>
                <div>
                  <input
                    type="number"
                    value={formatNumber(0.015 * totalVolume, 2)}
                    readOnly
                    className="focus:outline-none border border-gray-300 rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                  />
                  <p className="text-right text-sm">
                    KG of C02
                    <br />
                    shipping saving
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-4 hidden">
              <div className="flex flex-wrap justify-between">
                <div>
                  <label className="mb-2">C02 on bottle production</label>
                  <p className="text-sm text-gray-500">
                    100 gram lighter = 20% reduction
                  </p>
                </div>
                <input
                  type="number"
                  value={formatNumber((0.8 - 0.13333) * totalVolume, 1)}
                  readOnly
                  className="focus:outline-none border border-gray-300 rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">CO2 savings in KG per year</label>
                <input
                  type="number"
                  value={formatNumber(
                    0.015 * totalVolume + (0.8 - 0.13333) * totalVolume,
                    1
                  )}
                  readOnly
                  className="focus:outline-none border border-gray-300 rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap justify-between">
                <label className="mb-2">CO2 savings in trees per year</label>
                <div>
                  <input
                    type="number"
                    value={formatNumber(
                      (0.015 * totalVolume + (0.8 - 0.13333) * totalVolume) /
                        20,
                      1
                    )}
                    readOnly
                    className="focus:outline-none border border-gray-300 rounded-md px-3 py-2 text-right w-full lg:w-40 bg-transparent"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default RevinedCalc;