import React, { useState } from "react";
import TopBox from "../components/TopBox";
import HeadingH2 from "../components/HeadingH2";
import pdfUrl1 from "../assets/media/revined_quickguide.pdf";
import downloadButtonSvg from "../assets/media/downloadbtn.svg";
import YouTubeInPage from "../components/YouTubeInPage";

const Manual = ({ page }) => {
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailSubject = encodeURIComponent(subject);
    const emailBody = encodeURIComponent(`
      Company Name: ${companyName}
      First Name: ${firstName}
      Last Name: ${lastName}
      Country: ${country}
      Email: ${email}
      Phone: ${phone}
      Message: ${message}
    `);

    const mailtoLink = `mailto:info@revined.com?subject=${emailSubject}&body=${emailBody}`;

    window.location.href = mailtoLink;
  };
  const handleDownloadPdf1 = () => {
    window.open(pdfUrl1, "_blank");
  };

  const handleDownloadPdf2 = () => {
    const pdfUrl2 = pdfUrl1;
    window.open(pdfUrl2, "_blank");
  };
  return (
    <div>
      <TopBox data={page.page_content} />
      <div className="w-full py-12 bg-primaryBlue">
        <div className="container text-white">
          <HeadingH2>Downloads</HeadingH2>
          <div className="md:flex">
            <div className="flex gap-4 mb-8 md:w-1/3">
              <div className="cursor-pointer" onClick={handleDownloadPdf1}>
                <p className="text-center text-xl">Download</p>
                <img
                  src={downloadButtonSvg}
                  width={100}
                  height={100}
                  alt="QuickGuide"
                />
                <p className="text-center text-xl">QuickGuide</p>
              </div>
              <div className="cursor-pointer" onClick={handleDownloadPdf2}>
                <p className="text-center text-xl">Download</p>
                <img
                  src={downloadButtonSvg}
                  width={100}
                  height={100}
                  alt="PDF"
                />
                <p className="text-center text-xl">Manual</p>
              </div>
            </div>
            <div className="md:w-2/3 flex-grow">
              <YouTubeInPage
                videoId="rx9EkRm2XEE"
                thumbnailUrl="https://img.youtube.com/vi/rx9EkRm2XEE/maxresdefault.jpg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-12 bg-secondaryBg">
        <div className="container">
          <HeadingH2>Contact Us</HeadingH2>
          <form onSubmit={handleSubmit} className="w-full lg:w-2/3">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                Company name:
              </label>
              <input
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="flex gap-4 w-full">
              <div className="mb-4  w-full">
                <label className="block text-gray-700 text-sm mb-2">
                  First name:
                </label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-gray-700 text-sm mb-2">
                  Last name:
                </label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                Country:
              </label>
              <input
                type="text"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="flex gap-4 w-full">
              <div className="mb-4 w-full">
                <label className="block text-gray-700 text-sm mb-2">
                  Email address (business):
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-gray-700 text-sm mb-2">
                  Phone:
                </label>
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                Subject:
              </label>
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                Message:
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <button
              type="submit"
              className="h-100 px-[1rem] py-[.5rem] bg-primaryBlue text-white fill-white font-bold duration-300 hover:bg-primaryGold"
            >
              Submit
              <span className="inline-block  ms-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                >
                  <g>
                    <g>
                      <path d="M9.583 0L7.966 1.655l3.638 3.685H0v2.32h11.604l-3.638 3.685L9.583 13 16 6.5z" />
                    </g>
                  </g>
                </svg>
              </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Manual;
