import React, { useState } from "react";
import NavMenuItems from "./NavMenuItems";

const NavMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = () => {
    setIsOpen(false);
    window.scrollTo(0, 0);
  };

  return (
    <nav>
      <div className="block lg:hidden">
        <button onClick={toggleMenu} className="flex items-center z-50">
          {isOpen ? (
            <svg
              className="fixed right-[6vw] z-30 h-[20px] w-[20px] transition-transform rotate-90"
              width="12"
              height="12"
              viewBox="0 0 15 15"
              fill="#fff"
            >
              <path d="M1 15a1 1 0 01-.71-.29 1 1 0 010-1.41l5.8-5.8-5.8-5.8A1 1 0 011.7.29l5.8 5.8 5.8-5.8a1 1 0 011.41 1.41l-5.8 5.8 5.8 5.8a1 1 0 01-1.41 1.41l-5.8-5.8-5.8 5.8A1 1 0 011 15z"></path>
            </svg>
          ) : (
            <svg
              className="h-[25px] w-[25px]"
              width="18"
              height="18"
              viewBox="0 0 18 14"
              aria-hidden="true"
              data-type="type-2"
              fill="#fff"
            >
              <rect y="0.00" width="10" height="1.7" rx="1"></rect>
              <rect y="6.15" width="18" height="1.7" rx="1"></rect>
              <rect y="12.3" width="15" height="1.7" rx="1"></rect>
            </svg>
          )}
        </button>
      </div>
      <div
        className={`block fixed top-0 left-0 bottom-0 right-0 bg-secondaryBlue pt-16 lg:text-base lg:relative lg:flex lg:items-center lg:w-auto lg:visible lg:translate-y-0 lg:pt-0 transition-all ${
          isOpen
            ? "translate-y-0 container z-20"
            : "invisible translate-y-full "
        }`}
      >
        <div className="lg:flex-grow">
          <NavMenuItems
            handleMenuItemClick={handleMenuItemClick}
            isOpen={isOpen}
          />
        </div>
      </div>
    </nav>
  );
};

export default NavMenu;
