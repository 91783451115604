import topImage from "../assets/media/revinedbg.jpg";
import HeadingH2 from "./HeadingH2";
import YouTubeInPage from "./YouTubeInPage";
import { Link } from "react-router-dom";

const TopBox = ({ data }) => {
  const handleMenuItemClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="bg-secondaryBg">
      <div className="container mx-auto  min-h-[70vh] flex flex-wrap items-center z-10 relative">
        <div className="w-full lg:w-1/2 py-4 lg:p-20">
          <HeadingH2>{data.boxTitle}</HeadingH2>
          {data.boxSubTitle && (
            <h3 className="-mt-4 mb-5 text-primaryGold">{data.boxSubTitle}</h3>
          )}
          {data.box && (
            <p
              className="mb-5"
              dangerouslySetInnerHTML={{ __html: data.box }}
            />
          )}
          {data.boxBtn && (
            <Link
              to={data.boxBtnUrl}
              onClick={handleMenuItemClick}
              className="h-100 px-[1rem] py-[.5rem] bg-primaryBlue text-white fill-white font-bold duration-300 hover:bg-primaryGold"
            >
              {data.boxBtn}
              <span className="inline-block  ms-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                >
                  <g>
                    <g>
                      <path d="M9.583 0L7.966 1.655l3.638 3.685H0v2.32h11.604l-3.638 3.685L9.583 13 16 6.5z" />
                    </g>
                  </g>
                </svg>
              </span>
            </Link>
          )}
        </div>
        <div className="w-full lg:w-1/2 py-4 lg:p-20">
          {data.youtubeId ? (
            <YouTubeInPage videoId={data.youtubeId} thumbnailUrl={topImage} />
          ) : (
            <img src={topImage} alt={data.boxTitle} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBox;
