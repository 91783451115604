import Logo1 from "../assets/media/logoRobertParkerWine.webp";
import Case1 from "../assets/media/caseRobertParkerWine.jpg";
import Logo2 from "../assets/media/logoCatenaZapata.webp";
import Case2 from "../assets/media/caseCatenaZapata.jpg";
import Logo3 from "../assets/media/logoHighWest.webp";
import Case3 from "../assets/media/caseHighWest.jpg";
import Logo4 from "../assets/media/logoSalentein.webp";
import Case4 from "../assets/media/caseSalentein.jpg";
import Logo5 from "../assets/media/logoVinebox.webp";
import Case5 from "../assets/media/caseVinebox.jpg";
import Logo6 from "../assets/media/logoDVine.webp";
import Case6 from "../assets/media/caseDVine.jpg";

const UseCase = () => {
  const Cases = [
    {
      url: "https://wineintubes.com/?post_type=page&p=1552",
      logo: Logo1,
      case: Case1,
    },
    {
      url: "https://wineintubes.com/?post_type=page&p=73",
      logo: Logo2,
      case: Case2,
    },
    {
      url: "https://wineintubes.com/?post_type=page&p=2878",
      logo: Logo3,
      case: Case3,
    },
    {
      url: "https://wineintubes.com/?post_type=page&p=3001",
      logo: Logo4,
      case: Case4,
    },
    {
      url: "https://wineintubes.com/?post_type=page&p=2080",
      logo: Logo5,
      case: Case5,
    },
    {
      url: "https://wineintubes.com/?post_type=page&p=2098",
      logo: Logo6,
      case: Case6,
    },
  ];
  return (
    <>
      <div className="relative bg-secondaryBg w-full py-24">
        <div className="container lg:ps-20">
          <div className="mx-auto flex flex-wrap items-center z-10">
            {Cases.map((item, index) => (
              <div
                key={index}
                className="w-full lg:w-1/3 py-4 lg:pe-20 relative"
              >
                <div className="h-full w-full overflow-hidden">
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <img
                      src={item.case}
                      width={325}
                      height={215}
                      alt="case"
                      className="hover:scale-110 transition-transform max-h-[215px]"
                    />
                  </a>
                </div>
                <div className="absolute z-10 top-8 -left-10 p-3 rounded-full bg-white">
                  <img src={item.logo} alt="case logo" className="w-16 h-16" />
                </div>
              </div>
            ))}
          </div>
          <a
            href="https://wineintubes.com/portfolio/"
            target="_blank"
            rel="noreferrer"
            className="mt-4 mx-auto h-100 px-[1rem] py-[.5rem] bg-primaryBlue text-white fill-white font-bold duration-300 hover:bg-primaryGold"
          >
            More Cases
            <span className="inline-block  ms-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="13"
                viewBox="0 0 16 13"
              >
                <g>
                  <g>
                    <path d="M9.583 0L7.966 1.655l3.638 3.685H0v2.32h11.604l-3.638 3.685L9.583 13 16 6.5z" />
                  </g>
                </g>
              </svg>
            </span>
          </a>
        </div>
      </div>
    </>
  );
};

export default UseCase;
