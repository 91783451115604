const QuoteCustomer = ({ data }) => {
  console.log(data);
  return (
    <div className="relative bg-primaryBlue w-full py-24">
      <div className="container text-center lg:ps-20">
        <h3 className="text-white">
          <i>{data.quoteCustomer}</i>
        </h3>
        <br />
        <p className="text-primaryGold">By {data.quoteCustomerName}</p>
      </div>
    </div>
  );
};

export default QuoteCustomer;
