import { Link } from "react-router-dom";
import privacyPolicyPdf from "../../assets/media/privacyPolicyRevined.pdf";
import cookiesPdf from "../../assets/media/cookieStatementRevined.pdf";
import faqPdf from "../../assets/media/faqRevined.pdf";
import technicalDataSheetPdf from "../../assets/media/technicalDataSheetRevined.pdf";
import termsConditionsPdf from "../../assets/media/termsConditionsRevined.pdf";
const FooterLinks = () => {
  const links = [
    {
      name: "Privacy Policy",
      href: privacyPolicyPdf,
    },
    {
      name: "About Us",
      href: "/about-us",
    },
    {
      name: "Cookies",
      href: cookiesPdf,
    },
    {
      name: "FAQ",
      href: faqPdf,
    },
    {
      name: "Technical Data sheet",
      href: technicalDataSheetPdf,
    },
    {
      name: "Terms & Conditions",
      href: termsConditionsPdf,
    },
    {
      name: "Press",
      href: "https://wineintubes.com/news/tubes-launches-tubes-revined-a-revolutionary-wine-and-spirits-sampling-solution/",
    },
  ];
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      {links.map((item, index) => (
        <li key={index}>
          {item.name === "About Us" ? (
            <Link
              to={item.href}
              onClick={handleClick}
              className="text-white hover:text-primaryGold uppercase"
            >
              {item.name}
            </Link>
          ) : (
            <a
              href={item.href}
              target="_blank"
              rel="noreferrer"
              className="text-white hover:text-secondary uppercase"
            >
              {item.name}
            </a>
          )}
        </li>
      ))}
    </>
  );
};

export default FooterLinks;
