import React, { useEffect } from "react";
import HubspotContactForm from "../components/HubspotContactForm";

const OrderTubes = ({ page }) => {
  const formId = "c6fa4ab2-1520-4b74-9dde-863f3540f7dd";
  const title = "Order your tubes";
  useEffect((formId) => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: 5510076,
          formId: formId,
          target: "#hbspt-form-" + formId,
          region: "na1",
        });
      }
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <HubspotContactForm id={formId} title={title} />
    </>
  );
};

export default OrderTubes;
