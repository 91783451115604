import React from "react";
import NotFound from "../templates/NotFound";
import Home from "../templates/Home";
import PreOrderNow from "../templates/PreOrderNow";
import TheProduct from "../templates/TheProduct";
import UseCases from "../templates/UseCases";
import AboutUs from "../templates/AboutUs";
import Contact from "../templates/Contact";
import Manual from "../templates/Manual";
import OrderTubes from "../templates/OrderTubes";

const Main = ({ page }) => {
  switch (page.page_content.pageTemplate) {
    case "Home":
      return <Home page={page} />;

    case "Pre Order Now":
      return <PreOrderNow page={page} />;

    case "The Product":
      return <TheProduct page={page} />;

    case "Use Case":
      return <UseCases page={page} />;

    case "About Us":
      return <AboutUs page={page} />;

    case "Contact":
      return <Contact page={page} />;

    case "Manual":
      return <Manual page={page} />;

    case "Order Tubes":
      return <OrderTubes page={page} />;

    default:
      return <NotFound />;
  }
};

export default Main;
