import HeadingH2 from "./HeadingH2";
import WhyBlocks from "./WhyBlocks";

const Why = ({ data }) => {
  return (
    <section className="bg-primaryBlue relative w-full py-8">
      <div className="container text-white lg:px-20">
        <HeadingH2>
          {data.whyTitle1}
          <br />
          {data.whyTitle2}
        </HeadingH2>
        <h3 className="-mt-4 mb-5 text-primaryGold">{data.whySubTitle}</h3>
        <WhyBlocks data={data} />
      </div>
    </section>
  );
};

export default Why;
